import React, { useEffect, useState, useRef } from "react";
import { isMobile, isTablet } from "react-device-detect";
import Slider from "@material-ui/core/Slider";

import VectorLetter from "./VectorLetter";
import "./App.css";

function App() {
  const RUSSIAN_WARSHIP_GO_FUCK_YOURSELF = true;

  const scale = isMobile ? 0.6 : isTablet ? 0.8 : 1;

  const iLS = 105; // initial (render) letter size
  const lS = scale * iLS; // actual letter size

  const getSmallerDimension = (
    screenDimension: number,
    innerDimension: number
  ): number =>
    screenDimension < innerDimension ? screenDimension : innerDimension;

  const getWindowWidth = () =>
    getSmallerDimension(window.screen?.width, window.innerWidth);

  const [currentTheme, setCurrentTheme] = useState<string | null>(
    localStorage.getItem("theme")
  );
  const [windowWidth, setWindowWidth] = useState<number>(getWindowWidth());
  const [lettersWidth, setLettersWidth] = useState<number[]>([
    iLS,
    iLS,
    iLS,
    iLS,
    iLS,
    iLS,
    iLS,
  ]);

  const [percents, setPercents] = useState<number>(0);

  const slider = useRef() as React.MutableRefObject<HTMLDivElement>;

  const sRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const o1Ref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const lRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const o2Ref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const vRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const eRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const yRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const refs = [sRef, o1Ref, lRef, o2Ref, vRef, eRef, yRef];

  const animationPeriod = 5;

  const sliderStep = 1;

  const Letter = ({
    char,
    _ref,
    hidden,
    style = {},
  }: {
    char: string;
    _ref: React.MutableRefObject<HTMLDivElement>;
    hidden?: boolean;
    style: React.CSSProperties;
  }) => {
    const scale = 1;

    if (typeof style?.scale === "number") {
      style.scale *= scale;
    } else {
      style.scale = scale;
    }

    style.scale = style.scale.toString();

    if (hidden) style.display = "none";

    return (
      <div className={"letter"} ref={_ref} style={style}>
        <VectorLetter char={char} />
      </div>
    );
  };

  const S = () => {
    const startMarginLeft = 0;
    const endMarginLeft = (windowWidth - lettersWidth[0]) / 2;

    return (
      <Letter
        char={"S"}
        _ref={sRef}
        style={{
          left: ((endMarginLeft - startMarginLeft) / 100) * percents,
          transform: `scale(${scale})`,
        }}
      />
    );
  };

  const O = ({ second }: { second?: boolean }) => {
    const startMarginLeft = second
      ? ((windowWidth - lettersWidth[3]) / 6) * 3
      : (windowWidth - lettersWidth[1]) / 6;
    const endMarginLeft = (windowWidth - lettersWidth[0]) / 2 + lS / 5.2;
    const endDeg = 300;
    const endMarginTop = lS / 5.2;

    return (
      <Letter
        char={"0"}
        _ref={second ? o2Ref : o1Ref}
        style={{
          left:
            startMarginLeft +
            ((endMarginLeft - startMarginLeft) / 100) * percents,
          top: (endMarginTop / 100) * percents,
          transform: `rotate(${(endDeg / 100) * percents}deg) scale(${
            (1 + 0.0014 * percents) * scale
          })`,
        }}
      />
    );
  };

  const L = () => {
    const startMarginLeft = ((windowWidth - lettersWidth[2]) / 6) * 2;
    const endMarginLeft = (windowWidth - lettersWidth[0]) / 2 - lS / 4;
    const endMarginTop = 0;

    return (
      <Letter
        char={"1"}
        _ref={lRef}
        style={{
          left:
            startMarginLeft +
            ((endMarginLeft - startMarginLeft) / 100) * percents,
          top: (endMarginTop / 100) * percents,
          transform: `scale(${(1 - 0.002 * percents) * scale})`,
        }}
      />
    );
  };

  const V = () => {
    const endDeg = 90;
    const startMarginLeft = ((windowWidth - lettersWidth[4]) / 6) * 4;
    const endMarginLeft = (windowWidth - lettersWidth[0]) / 2 - lS / 10.5;
    const endMarginTop = lS / -3.1;

    return (
      <Letter
        char={"V"}
        _ref={vRef}
        style={{
          left:
            startMarginLeft +
            ((endMarginLeft - startMarginLeft) / 100) * percents,
          transform: `rotate(${(endDeg / 100) * percents}deg) scale(${
            (1 - 0.0084 * percents) * scale
          })`,
          top: (endMarginTop / 100) * percents,
        }}
      />
    );
  };

  const E = () => {
    const endDeg = 30;
    const startMarginLeft = ((windowWidth - lettersWidth[5]) / 6) * 5;
    const endMarginLeft = (windowWidth - lettersWidth[0]) / 2 + lS / 2.1875;
    const endMarginTop = lS / 2.55;

    return (
      <Letter
        char={"E"}
        _ref={eRef}
        style={{
          left:
            startMarginLeft +
            ((endMarginLeft - startMarginLeft) / 100) * percents,
          top: (endMarginTop / 100) * percents,
          transform: `rotate(${(endDeg / 100) * percents}deg) scale(${
            (1 - 0.0061 * percents) * scale
          })`,
        }}
      />
    );
  };

  const Y = () => {
    const endDeg = 180;
    const startMarginLeft = windowWidth - lettersWidth[6];
    const endMarginLeft = (windowWidth - lettersWidth[0]) / 2 - lS / 12;
    const endMarginTop = lS / 3;

    return (
      <Letter
        char={"Y"}
        _ref={yRef}
        style={{
          left:
            startMarginLeft +
            ((endMarginLeft - startMarginLeft) / 100) * percents,
          top: (endMarginTop / 100) * percents,
          transform: `rotate(${(endDeg / 100) * percents}deg) scale(${
            (1 - 0.006 * percents) * scale
          })`,
        }}
      />
    );
  };

  const updateLettersWidth = () => {
    let lws = [];

    for (let i = 0; i < refs.length; i++) {
      const w = refs[i].current.offsetWidth;
      lws[i] = isNaN(w) ? 0 : w;
    }

    setLettersWidth(lws);
  };

  const startAnimation = async () => {
    const timeout = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    let stepsCount = 0;

    const step = (i: number) => {
      setPercents(i);

      stepsCount++ % 10 === 0 && updateLettersWidth();
    };

    await timeout(1000);

    let i = 0;
    for (; i <= 100; i += sliderStep) {
      await timeout(animationPeriod);

      step(i);
    }

    await timeout(1000);

    for (; i >= 0; i -= sliderStep) {
      await timeout(animationPeriod);

      step(i);
    }

    updateLettersWidth();
  };

  const switchTheme = (
    newTheme: string = currentTheme === "white" ? "red" : "white"
  ) => {
    if (RUSSIAN_WARSHIP_GO_FUCK_YOURSELF) return;

    setCurrentTheme(newTheme);

    document.querySelector(
      ".MuiSlider-root"
    )!.className = `MuiSlider-root ${newTheme}`;
    setBodyTheme(newTheme);

    localStorage.setItem("theme", newTheme);
  };

  const onLogoClick = () => switchTheme();

  const runAnimation = async () => {
    slider.current.setAttribute(
      "style",
      "pointer-events: none;touch-action: none;"
    );

    await startAnimation().then(() => slider.current.removeAttribute("style"));
  };

  const upatedWindow = (): void => {
    setWindowWidth(getWindowWidth());
    // setWindowHeight(getWindowHeight());
    updateLettersWidth();
  };

  const setBodyTheme = (currentTheme: string | null) =>
    (document.body.className = currentTheme || "white");

  useEffect(() => {
    upatedWindow();

    runAnimation();

    switchTheme(currentTheme || "white");

    window.addEventListener("resize", upatedWindow);

    return () => window.removeEventListener("resize", upatedWindow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSliderChange = (_: any, newValue: any) => {
    setPercents(newValue);
    updateLettersWidth();
  };

  return (
    <div>
      {RUSSIAN_WARSHIP_GO_FUCK_YOURSELF && (
        <div>
          <div
            style={{
              backgroundColor: "#22A7F0",
              width: "100%",
              height: "50%",
              position: "absolute",
            }}
          />
          <div
            style={{
              backgroundColor: "#F4D03F",
              width: "100%",
              height: "50%",
              top: "50%",
              position: "absolute",
            }}
          />
        </div>
      )}
      <div className={"touchable-opacity"} onClick={onLogoClick} />
      <div
        style={{
          position: "absolute",
          marginTop: `calc(50vh - ${iLS / 2}px)`,
          filter: `invert(${
            currentTheme === "red" && !RUSSIAN_WARSHIP_GO_FUCK_YOURSELF ? 1 : 0
          })`,
        }}
      >
        <S />
        <O />
        <L />
        <O second />
        <V />
        <E />
        <Y />
      </div>
      <div ref={slider} className={"slider-area"}>
        <Slider
          value={percents}
          min={0}
          max={100}
          step={sliderStep}
          onChange={onSliderChange}
        />
      </div>
    </div>
  );
}

export default App;
